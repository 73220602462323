<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <div class="d-flex flex-column justify-space-between align-center mb-4">
            <v-img
              :width="200"
              src="@/assets/images/ASGS-Logo-rgb.webp"
            />
          </div>
          <div
            v-if="!requestSent"
            class=""
          >
            <h6 class="text--disabled font-weight-medium mb-10">
              Passwort zurücksetzen
            </h6>
            <v-text-field
              v-model="email"
              label="Email"
              :rules="emailRules"
              :error-messages="emailErrors"
              required
            />
            <v-btn
              class="mt-4 mb-4"
              block
              color="primary"
              dark
              @click="resetPasswordRequest"
            >
              Senden
            </v-btn>
            <div class="">
              <v-btn
                text
                small
                color="primary"
                to="/"
              >
                Sign In
              </v-btn>
            </div>
          </div>
          <h6
            v-else
            class="text--disabled font-weight-medium mb-10"
          >
            Request sent. Check your email.
          </h6>
        </v-card-text>
        <v-snackbar
          v-model="snackbar"
          top
          :color="colorSnackbar"
        >
          {{ messageSnackbar }}
          <template v-slot:action="{attrs}">
            <v-btn
              color=""
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </base-card>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import axios from 'axios'
  import { validationMixin } from 'vuelidate'
  import { required, email, minLength, maxLength, integer, between } from 'vuelidate/lib/validators'

  export default {
    name: 'ResetPasswordRequest',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Registrierung',
    },
    mixins: [validationMixin],
    data () {
      return {
        show: false,
        email: '',
        loading: false,
        snackbar: false,
        colorSnackbar: 'danger',
        messageSnackbar: 'Invalid Email',
        requestSent: false,
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      }
    },

    methods: {
      ...mapActions(['signUserUp']),
      resetPasswordRequest () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.snackbar = true
          return
        }
        axios.post(`${process.env.VUE_APP_SMT_API_URL}/user/password/reset`, {
          s_Email: this.email,
          s_BaseUrl: window.location.origin + `/${this.$i18n.locale}`,
        })
          .then(response => {
            this.colorSnackbar = 'success'
            this.messageSnackbar = 'Email sent'
            this.snackbar = true
            this.requestSent = true
          })
          .catch(error => {
            this.colorSnackbar = 'danger'
            this.messageSnackbar = 'Error'
            this.snackbar = true
          })
      },
    },
    computed: {
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) {
          return errors
        }
        !this.$v.email.required && errors.push('Email is required')
        !this.$v.email.email && errors.push('Invalid Email')
        return errors
      },
    },
    validations: {
      email: {
        required,
        email,
      },
    },
    beforeRouteEnter(to, from, next) {
      if (
        localStorage.getItem('userInfo') != null &&
        localStorage.getItem('userInfo').length > 0 
      ) { next('/') }
      else next()
    },
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
